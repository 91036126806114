import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import RichTextEditor from "../../components/RichTextEditor";
import { useEditBlogsMutation, useGetBlogDataMutation, useDeleteBlogsMutation } from "../../store/services/blogs";
import { useEffect, useState } from "react";
import axios from "axios";
import TagsInput from "react-tagsinput";
import "./reactTagsStyling.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

let initialValues = {
  page_id: 0,
  page_title: "",
  page_slug: "",
  page_descr: "",
  blog_short_descr: "",
  blog_author: "",
  page_featured: "",
  page_banner: "",
  page_keywords: [],
  // {tag: "adsjoboui"}
  page_read: "",
  page_category: 1,
  page_date: new Date(),
  status: 0,
};

const EditBlogs = () => {
  const [description, setDescription] = useState("");
  const { id } = useParams();

  const toast = useToast();
  const navigate = useNavigate();
  const [editBlogs, { isSuccess: blogSuccess, isError: editBlogError, data: editBlogData }] = useEditBlogsMutation();

  const [getBlogData, { data: blogData, error: blogError, isSuccess: isBlogDataFetchSuccess }] = useGetBlogDataMutation();

  const [deleteBlogs, { isSuccess: deleteBlogSuccess, isError: deleteBlogError, data: deleteBlogData }] = useDeleteBlogsMutation();

  useEffect(() => {
    if (deleteBlogSuccess) {
      toast({
        title: "Blog Deleted Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/blogs");
    }
    if (deleteBlogError) {
      toast({
        title: "Error Deleting Blog",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [deleteBlogSuccess, deleteBlogError]);

  useEffect(() => {
    if (blogSuccess) {
      toast({
        title: "Blog Edited Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/blogs");
    }
    if (editBlogError) {
      toast({
        title: "Error Editing Blog",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [blogSuccess, editBlogError]);

  useEffect(() => {
    if (id) {
      getBlogData(encodeURIComponent(id));
    }
  }, [id]);

  useEffect(() => {
    if (isBlogDataFetchSuccess) {
      setFieldValue("page_id", blogData.page_id);
      setFieldValue("page_title", blogData.page_title);
      setFieldValue("page_descr", blogData.page_descr);
      setFieldValue("blog_short_descr", blogData.blog_short_descr);
      setFieldValue("blog_author", blogData.blog_author);
      setFieldValue("page_featured", blogData.page_featured);
      setFieldValue("page_banner", blogData.page_banner);
      setFieldValue("page_keywords", JSON.parse(blogData.page_keywords));
      setFieldValue("page_read", blogData.page_read);
    }
  }, [isBlogDataFetchSuccess, blogData]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      // setFieldValue("page_keywords", JSON.stringify(values.page_keywords));

      if (
        values.page_title == "" ||
        values.page_descr == "" ||
        values.blog_short_descr == "" ||
        values.page_featured == "" ||
        values.page_banner == "" ||
        values.page_read == ""
      ) {
        alert("Please fill all the fields");
        return;
      }

      let finalObj = {
        ...values,
        page_keywords: JSON.stringify(values.page_keywords),
        page_category: 2,
        status: "0",
        page_slug: values.page_title.split(" ").join("-"),
      };
      editBlogs(finalObj);
      // action.resetForm();
    },
  });

  const handleFeatureImageChange = async (e) => {
    let imageName = await uploadImage(e.target.files[0]);
    setFieldValue("page_featured", imageName);
  };

  const handleBannerImageChange = async (e) => {
    let imageName = await uploadImage(e.target.files[0]);
    setFieldValue("page_banner", imageName);
  };

  const handleKeywordChange = (tags) => {
    console.log("Tags --> ", tags);
    const keywordsArray = tags.map((tag) => ({ tag }));
    setFieldValue("page_keywords", keywordsArray);
  };

  const uploadImage = async (image) => {
    const formData = new FormData();
    formData.append("file", image);
    try {
      const response = await axios.post("https://dashboard.praan.io/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data.filename; // Assuming the API returns the filename
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  return (
    <div className="p-5 pt-10">
      <div className="flex justify-between mb-8">
        <h1 className="text-3xl font-bold">Edit Blogs</h1>
        <button onClick={() => deleteBlogs(values.page_id)} className="bg-red-500 px-5 py-1 text-white rounded-md">
          Delete
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mt-4">
          <label className="mr-4 block text-lg font-semibold" htmlFor="title">
            Blog Title
          </label>
          <input
            value={values.page_title}
            onChange={handleChange}
            className="w-full rounded mt-2 border h-12 px-4"
            type="text"
            name="page_title"
            id="page_title"
          />
        </div>
        <div className="mt-8">
          <label className="mr-4 block text-lg font-semibold" htmlFor="keywords">
            SEO Keywords
          </label>
          {/* <input className="w-full rounded mt-2 border h-12 px-4" type="text" name="keywords" id="keywords" /> */}
          <TagsInput
            maxTags={9}
            addOnBlur
            addOnPaste={true}
            pasteSplit={function defaultPasteSplit(data) {
              return data.split(",").map((d) => d.trim());
            }}
            value={values.page_keywords.map((keyword) => keyword.tag)}
            onChange={handleKeywordChange}
          />
        </div>
        <div className="mt-8">
          <label className="mr-4 block text-lg font-semibold" htmlFor="short_description">
            SEO Meta / Short Description (max 250 Chars)
          </label>

          <input
            value={values.blog_short_descr}
            onChange={handleChange}
            className="w-full rounded mt-2 border h-12 px-4"
            type="text"
            name="blog_short_descr"
            id="blog_short_descr"
          />
        </div>
        <div className="mt-8">
          {values.page_featured && (
            <img className="w-52 object-contain" src={`https://dashboard.praan.io/uploads/${values.page_featured}`} alt="feature" />
          )}
          <label className="mr-4 block text-lg font-semibold bg-black rounded px-5 py-1 text-white w-fit cursor-pointer" htmlFor="feature_image">
            Feature Image ( Recommended Image Size (986 x 502px))
          </label>
          <input
            onChange={handleFeatureImageChange}
            className="w-full rounded mt-2 border h-12 px-4 hidden"
            type="file"
            name="feature_image"
            id="feature_image"
          />
        </div>
        <div className="mt-8">
          {values.page_banner && (
            <img className="w-52 object-contain" src={`https://dashboard.praan.io/uploads/${values.page_banner}`} alt="feature" />
          )}
          <label className="mr-4 block text-lg font-semibold bg-black rounded px-5 py-1 text-white w-fit cursor-pointer" htmlFor="page_banner">
            Banner Image ( Recommended Image Size (1920 x 800px))
          </label>
          <input
            onChange={handleBannerImageChange}
            className="w-full rounded mt-2 border h-12 px-4 hidden"
            type="file"
            name="page_banner"
            id="page_banner"
          />
        </div>
        <div className="mt-8">
          <label className="mr-4 block text-lg font-semibold" htmlFor="short_description">
            Readtime (ex. 5 min )
          </label>
          <input
            value={values.page_read}
            onChange={handleChange}
            className="w-full rounded mt-2 border h-12 px-4"
            type="text"
            name="page_read"
            id="page_read"
          />
        </div>
        <div className="mt-8 mb-10">
          <label className="mr-4 block text-lg font-semibold" htmlFor="short_description">
            Description
          </label>
          {/* <input id="x" type="hidden" name="content" /> */}
          <RichTextEditor
            value={values.page_descr}
            setValue={(val) => {
              console.log("val", val);
              // setDescription(val);
              setFieldValue("page_descr", val);
            }}
          />
        </div>

        <div className="flex justify-center">
          <button className="bg-black text-white px-4 py-2 rounded-md mb-10 " type="submit">
            Edit Blog
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditBlogs;
