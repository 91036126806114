import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const careerApis = createApi({
  reducerPath: "careerApis",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    // baseUrl: "https://gg5s3lsr-3000.inc1.devtunnels.ms/",
    baseUrl: "https://dashboard.praan.io/api/",
    // baseUrl: "https://praanwebsitebackend.azurewebsites.net/",
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  endpoints: (builder) => ({
    // login api call
    getPositions: builder.mutation({
      query: (filter) => {
        return {
          url: `/positions/`,
          method: "get",
          params: filter,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
  }),
});

export const { useGetPositionsMutation } = careerApis;
