import { useAppDispatch } from "../store/hooks";
import { useNavigate } from "react-router-dom";
import { logout } from "../store/features/authSlice";
import logo from "../assets/images/praanwt.svg";

import { LuLogOut } from "react-icons/lu";

function Header({ isAuthenticated }) {
  // toggling the dark mode state to toggle darkmode

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //clearing localstorage and redirecting user to login page after logout
  const logoutUser = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <nav className=" z-10 fixed top-0 h-16 flex items-center justify-between px-10  w-full border-gray-200 bg-gray-900">
      <div className=" flex items-center justify-between p-4">
        <a href="" className="flex items-center ">
          <img className="w-16" src={logo} alt="" />
          {/* <span className="self-center text-2xl font-semibold whitespace-nowrap text-white"> Admin Panel</span> */}
        </a>
      </div>

      <div className="flex md:order-2">
        {isAuthenticated && (
          <button type="button" className=" mx-2 text-white   " onClick={logoutUser}>
            <LuLogOut className="inline-block  text-xl text-white " />
          </button>
        )}
      </div>
    </nav>
  );
}

export default Header;
