import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const blogsApi = createApi({
  reducerPath: "blogsApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    // baseUrl: "https://gg5s3lsr-3000.inc1.devtunnels.ms/",
    baseUrl: "https://dashboard.praan.io/api/",
    // baseUrl: "http://localhost:3005/api/",
    // baseUrl: "https://praanwebsitebackend.azurewebsites.net/",
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  endpoints: (builder) => ({
    // login api call
    getBlogs: builder.mutation({
      query: () => {
        return {
          url: `/blogs/get`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    getBlogData: builder.mutation({
      query: (blogId) => {
        return {
          url: `/blogs/${blogId}`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    createBlogs: builder.mutation({
      query: (body) => {
        return {
          url: `/blogs/`,
          method: "post",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    editBlogs: builder.mutation({
      query: (body) => {
        return {
          url: `/blogs/update/${body.page_id}`,
          method: "put",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    deleteBlogs: builder.mutation({
      query: (blogId) => {
        return {
          url: `/blogs/delete/${blogId}`,
          method: "delete",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    uploadImage: builder.mutation({
      query: (file) => {
        return {
          url: "/api/v1/upload",
          method: "post",
          body: file,
        };
      },
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
  }),
});

export const {
  useGetBlogsMutation,
  useUploadImageMutation,
  useCreateBlogsMutation,
  useGetBlogDataMutation,
  useEditBlogsMutation,
  useDeleteBlogsMutation,
} = blogsApi;
