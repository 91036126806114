import { useEffect, useState } from "react";
import moment from "moment";
import "./careerApp.css";
import { useGetApplicantsMutation, useChangeApplicationStatusMutation } from "../../store/services/applicants";
import { useGetPositionsMutation } from "../../store/services/positionsApi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ApplicationAccordion from "../../components/ApplicantsAccordion";
import CustomDropdown from "../../components/CustomSelect";
import { useNavigate } from "react-router-dom";
import PaginationButtons from "../../components/Pagination";

import { useParams } from "react-router-dom";
import PdfViewer from "../../components/PdfViwer";
const CareerApplicants = () => {
  //importing api mutation to call api and access data

  const { position, status } = useParams();
  const [defaultApplication, setdefaultApplication] = useState({});
  const [selectedApp, setSelectedApp] = useState({});
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    // status: "",
    role: position,
    pageSize: 100000,
    page: 0,
    sortByJoining: false,
    search: "",
  });

  const [getApplicants, { data: applicantsData, error: applicantsError, isError: isApplicantFetchError, isSuccess: isApplicantFetchSuccess }] =
    useGetApplicantsMutation();

  useEffect(() => {
    console.log("get ");
    getApplicants(filter);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isApplicantFetchSuccess) {
      setdefaultApplication(applicantsData?.[status]?.[0]);
      setSelectedApp(applicantsData?.[status]?.[0]);
    }
  }, [isApplicantFetchSuccess, applicantsData]);

  const refetch = () => {
    getApplicants(filter);
    window.scrollTo(0, 0);
  };

  return (
    <main className=" w-full  flex-col flex p-10 absolute left-0 top-0 bg-white">
      <h1 className="text-2xl font-bold inline-block">
        <span className="text-xl mr-4 font-bold inline-block cursor-pointer" onClick={() => navigate(-1)}>
          {" "}
          🔙{" "}
        </span>
        {status == "list0"
          ? "Applied"
          : status == "list1"
          ? "Interview/Task"
          : status == "list2"
          ? "Offered"
          : status == "list3"
          ? "Accepted"
          : "Rejected"}{" "}
        Applicants
      </h1>
      {applicantsData && (
        <div className="parent flex ">
          <div className="left w-3/12">
            <div className="filters w-full relative my-5">
              <div className="search  flex-1">
                <div className="">
                  <h2 className="font-semibold">Search</h2>
                  <div className="flex">
                    <input
                      className="bg-gray-100 h-10 rounded-md px-3"
                      value={filter.search}
                      onChange={(e) => {
                        setFilter((val) => {
                          return { ...val, search: e.target.value };
                        });
                      }}
                      placeholder="Email / Name"
                    />
                    <button
                      onClick={() => {
                        setFilter((val) => {
                          return { ...val, sortByJoining: !val.sortByJoining };
                        });
                      }}
                      className="w-full px-2 py-2  rounded-md ml-4 "
                    >
                      Sort By: {!filter.sortByJoining ? "None" : "Joining date"}
                    </button>
                  </div>
                  <div className="flex mt-5">
                    <button className="bg-blue-400 w-full px-2 py-2 rounded-lg  text-white" onClick={() => refetch()}>
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full mt-5 h-[60vh] overflow-y-scroll overflow-x-hidden px-2">
              <ApplicationAccordion
                refetch={refetch}
                applicantsData={applicantsData?.[status]}
                defaultApplication={defaultApplication}
                selectedApplicant={selectedApp}
                setSelectedApplicant={setSelectedApp}
              />
            </div>
          </div>
          <div className="right w-3/6 flex-1">
            {/* <PDFViewer
                document={{
                  url: `https://admin.praan.io/pdf/${selectedApp.career_resume}`,
                }}
              /> */}
            {selectedApp && <PdfViewer url={`https://admin.praan.io/pdf/${selectedApp?.career_resume}`} />}
          </div>
        </div>
      )}
    </main>
  );
};

export default CareerApplicants;
