import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // for Snow theme
import "react-quill/dist/quill.bubble.css"; // for Snow theme
import axios from "axios";
import "./rteEditor.css";

const { Quill } = ReactQuill;

let AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);
let BackgroundStyle = Quill.import("attributors/style/background");
Quill.register(BackgroundStyle, true);
let ColorStyle = Quill.import("attributors/style/color");
Quill.register(ColorStyle, true);

let sizeStyle = Quill.import("attributors/style/size");
sizeStyle.whitelist = ["75%", "100%", "125%", "150%", "200%", "400%"];
Quill.register(sizeStyle, false);

function MyRichTextEditor({ value, setValue }) {
  const [showHtml, setShowHtml] = useState(false);

  const modules = {
    toolbar: [
      // [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ header: "1" }, { header: "2" }],
      [{ size: sizeStyle.whitelist }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
      [{ color: [] }, { background: [] }], // add color and background color buttons
      [{ align: [] }], // text alignment options
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const formats = [
    "header",
    // "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "color",
    "background", // add color and background formats
    "align",
  ];

  const toggleHtml = (e) => {
    e.preventDefault();
    setShowHtml(!showHtml);
  };

  const quillRef = useRef(null);

  useEffect(() => {
    if (!showHtml && quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.root.innerHTML = value;
    }
  }, [showHtml]);

  const imageHandler = async (image, callback) => {
    const formData = new FormData();
    formData.append("file", image);

    try {
      const response = await axios.post("https://dashboard.praan.io/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const imageUrl = "https://dashboard.praan.io/uploads/" + response.data.filename; // Assuming the server responds with the uploaded image URL
      callback(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    const quill = quillRef.current.getEditor();
    quill.on("editor-change", (eventName, ...args) => {
      if (eventName === "text-change") {
        const images = quill.root.querySelectorAll("img");
        images.forEach((image) => {
          image.style.width = "100%";
        });
      }
    });
    quill.getModule("toolbar").addHandler("image", () => {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = async () => {
        const file = input.files[0];
        if (file) {
          imageHandler(file, (imageUrl) => {
            const range = quill.getSelection();
            quill.insertEmbed(range.index, "image", imageUrl);
          });
        }
      };
    });
  }, []);

  return (
    <div className="mt-2">
      <p className="text-right absolute right-6 -translate-y-full">
        {/* <button className="bg-black rounded ml-auto text-white px-4 mb-3 mt-2 " onClick={toggleHtml}>
          {showHtml ? "Hide HTML" : "Show HTML"}
        </button> */}
      </p>
      {showHtml ? (
        <textarea value={value} onChange={(e) => setValue(e.target.value)} style={{ width: "100%", height: "300px" }} />
      ) : (
        <ReactQuill
          className="bg-white rounded "
          ref={quillRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={value}
          onChange={(content) => setValue(content)}
        />
      )}
    </div>
  );
}

export default MyRichTextEditor;
