import { useEffect } from "react";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";

import { useCreateUserMutation } from "../../store/services/authApi";

const CreateUser = () => {
  //importing api mutation to call api and access data

  const [createUser, { data: userData, error: userError, isError: isUserError, isSuccess: isUserSuccess }] = useCreateUserMutation();

  const toast = useToast();

  const initialValues = {
    name: "",
    email: "",
    password: "",
    user_type: "1",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validate: (values) => {
      const errors = {};

      // Name validation
      if (!values.name) {
        errors.name = "Name is required";
      }
      // Email validation
      if (!values.email) {
        errors.email = "Email is required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Invalid email address";
      }
      // Password validation
      if (!values.password) {
        errors.password = "Password is required";
      } else if (values.password.length < 8) {
        errors.password = "Password should be at least 8 characters long";
      }

      return errors;
    },
    onSubmit: (values, action) => {
      values.user_type = "1";
      createUser(values);
      console.log(values);
      action.resetForm();
    },
  });

  useEffect(() => {
    if (isUserSuccess) {
      toast({
        title: "Success",
        status: "success",
        isClosable: true,
      });
    }
  }, [isUserSuccess, userError]);

  useEffect(() => {
    if (isUserError) {
      console.log("user error ->", userError);
      toast({
        title: `${userError.message ? userError.message : "Error Creating User, Please Try Again Later"}`,
        status: "error",
        isClosable: true,
      });
    }
  }, [isUserError, userError]);

  return (
    <div className=" w-full h-[calc(100vh-4rem)] flex-col justify-center flex p-10">
      <h1 className="w-full text-center text-2xl font-bold m-4 uppercase ">Create New Admin User</h1>
      <div className="form">
        <form onSubmit={handleSubmit} className="flex flex-col text-black ">
          <p className="font-medium text-md ">Enter Name</p>
          <div className="flex items-center w-full bg-white border my-2 p-4 rounded-md focus:outline-cyan-500">
            <input
              className="bg-white  w-full focus:outline-none "
              type="name"
              name="name"
              id="name"
              placeholder="Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          {errors.name && touched.name ? <p className="text-red-600">{errors.name}</p> : null}

          <p className="font-medium text-md ">Enter Email</p>
          <div className="flex items-center w-full bg-white border  my-2 p-4 rounded-md focus:outline-cyan-500">
            <input
              className="bg-white  w-full focus:outline-none "
              type="text"
              name="email"
              id="email"
              placeholder="abc@company.com"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          {errors.email && touched.email ? <p className="text-red-600">{errors.email}</p> : null}

          <p className="font-medium text-md  mt-4">Enter Password</p>
          <div className="flex items-center w-full bg-white border my-2 p-4 rounded-md focus:outline-cyan-500">
            <input
              className="bg-white  w-full focus:outline-none "
              type="password"
              name="password"
              id="password"
              placeholder="*******"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          {errors.password && touched.password ? <p className="text-red-600">{errors.password}</p> : null}

          <div className="flex justify-center mt-8">
            <button
              type="submit"
              className=" uppercase bg-blue-500 text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0"
            >
              Create User
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateUser;
