import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    // baseUrl: "https://gg5s3lsr-3000.inc1.devtunnels.ms/",
    baseUrl: "https://dashboard.praan.io/api/",
    // baseUrl: "https://praanwebsitebackend.azurewebsites.net/",
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      if (localStorage.getItem("user")) {
        const token = JSON.parse(localStorage.getItem("user") || "").token;
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }
      }
    },
  }),
  endpoints: (builder) => ({
    // login api call
    login: builder.mutation({
      query: (body) => {
        return {
          url: "/auth/login",
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
    // signup api call
    createUser: builder.mutation({
      query: (body) => {
        return {
          url: "/auth/createUser",
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("erorasda", response);
        return response.data;
      },
    }),
  }),
});

export const { useLoginMutation, useCreateUserMutation } = authApi;
