import React, { useState, useEffect } from "react";

const PdfViewer = ({ url }) => {
  return (
    <div className="mt-5 pl-10 h-[70vh]">
      <embed src={url} type="application/pdf" width="100%" height="100%" />
    </div>
  );
};

export default PdfViewer;
