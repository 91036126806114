import { useEffect, useState } from "react";
import { useGetPositionsMutation } from "../../store/services/positionsApi";
import CustomDropdown from "../../components/CustomSelect";
import { useNavigate } from "react-router-dom";

const CareerLists = () => {
  const [positionOptions, setPositionOptions] = useState([]);
  const [inActivePositionOptions, setInActivePositionOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const [getPositions, { data: positionsData, error: positionsError, isError: isPositionsFetchError, isSuccess: isPositionsFetchSuccess }] =
    useGetPositionsMutation();

  useEffect(() => {
    getPositions();
  }, []);

  useEffect(() => {
    if (isPositionsFetchSuccess) {
      let positions = positionsData?.active?.map((position) => {
        return { label: position.car_title, value: position.car_title };
      });
      setPositionOptions(positions);
      let inactivePositions = positionsData?.inactive?.map((position) => {
        return { label: position.car_title, value: position.car_title };
      });
      setInActivePositionOptions(inactivePositions);
    }
  }, [isPositionsFetchSuccess, positionsData]);

  // Function to filter job posts based on the search query
  const filterPositions = (positions) => {
    return positions.filter((position) => position.label.toLowerCase().includes(searchQuery.toLowerCase()));
  };

  return (
    <main className="w-full bg-white flex-col flex p-10">
      <div className="filters w-full relative my-2">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Job Postings</h1>

          {/* Search bar */}
          <input
            type="text"
            placeholder="Search job postings..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded-md px-3 py-2 mt-4"
          />
        </div>

        <div className="dropdown flex-1 mt-5">
          <h2 className="font-semibold">Active Job Posts</h2>
          <div className="grid grid-cols-3 w-full gap-5 mt-5">
            {filterPositions(positionOptions).map((position) => (
              <div
                key={position.label}
                className="bg-blue-200 p-3 py-4 rounded-lg cursor-pointer"
                onClick={() => navigate(`/career/${encodeURIComponent(position.label)}`)}
              >
                {position.label}
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1 mt-10">
          <h2 className="font-semibold">InActive Job Posts</h2>
          <div className="grid grid-cols-3 w-full gap-5 mt-5">
            {filterPositions(inActivePositionOptions).map((position) => (
              <div
                key={position.label}
                className="bg-blue-200 p-3 py-4 rounded-lg cursor-pointer"
                onClick={() => navigate(`/career/${encodeURIComponent(position.label)}`)}
              >
                {position.label}
              </div>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default CareerLists;
