import { useEffect, useState } from "react";
import { useGetBlogsMutation } from "../../store/services/blogs";
import CustomDropdown from "../../components/CustomSelect";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Blogs = () => {
  const [positionOptions, setPositionOptions] = useState([]);
  const [inActivePositionOptions, setInActivePositionOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [rebuildWebsite, setRebuildWebsite] = useState(false);
  const navigate = useNavigate();

  const [getBlogs, { data: blogsData, error: positionsError, isError: isPositionsFetchError, isSuccess: isBlogsFetchSuccess }] =
    useGetBlogsMutation();

  useEffect(() => {
    getBlogs();
  }, []);

  const onRebuildWebsite = async () => {
    try {
      const response = await axios.post("https://webhook.praan.io/start-build");
      console.log(response);
      if (response.status === 200) {
        alert("Rebuild Website Started");
        setRebuildWebsite(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isBlogsFetchSuccess) {
      setPositionOptions(blogsData);
    }
  }, [isBlogsFetchSuccess, blogsData]);

  // Function to filter job posts based on the search query
  const filterPositions = (positions) => {
    return positions.filter((position) => position.page_title.toLowerCase().includes(searchQuery.toLowerCase()));
  };

  return (
    <main className="w-full bg-white flex-col flex p-10">
      <div className="filters w-full relative my-2">
        {rebuildWebsite && (
          <div className="fixed top-0 left-0 h-screen bg-black bg-opacity-10 w-screen flex justify-center items-center">
            <div className="w-1/2 h-1/2 bg-white p-5 flex flex-col items-center justify-evenly">
              <h1 className="text-2xl font-bold">Rebuilding Website</h1>
              <p className="text-center">
                Rebuiling website will cause the website to remain down for 1-2 mins, please continue only if you are sure what you are doing
              </p>
              <div className="flex gap-5 ">
                <button onClick={onRebuildWebsite} className="px-6 py-1 bg-red-500 rounded-md text-white text-lg uppercase">
                  Rebuild
                </button>
                <button onClick={() => setRebuildWebsite(false)} className="px-6 py-1 bg-black rounded-md text-white text-lg uppercase">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Blogs</h1>

          <div className="flex gap-5">
            <button onClick={() => setRebuildWebsite(true)} className="px-6 py-1 bg-red-500 rounded-md text-white text-lg uppercase">
              Rebuild Website
            </button>

            <button onClick={() => navigate("/createBlogs")} className="px-6 py-1 bg-black rounded-md text-white text-lg uppercase">
              New Blog
            </button>
          </div>
          {/* Search bar */}
          {/* <input
            type="text"
            placeholder="Search job postings..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded-md px-3 py-2 mt-4"
          /> */}
        </div>

        <div className="dropdown flex-1 mt-5">
          <div className="grid grid-cols-3 w-full gap-5 mt-5">
            {filterPositions(positionOptions).map((position) => (
              <div
                key={position.page_title}
                onClick={() => navigate(`/blogs/${encodeURIComponent(position.page_slug)}`)}
                className="bg-blue-200 p-3 py-4 rounded-lg cursor-pointer"
              >
                {position.page_title}
                <p className="mt-3">
                  No Of Views - <span className="font-semibold"> {position.noOfClicks}</span>{" "}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Blogs;
